function greetAnimation(delay) {
    let animationBlock = document.createElement('div');
    animationBlock.innerHTML = `<div class="animation-block"
                                    <h1 class = "title greetings_animation"><h1>&ltDARACODE/&gt</h1></h1>
                                </div>`;
    document.body.appendChild(animationBlock);
    setTimeout(function(){
        animationBlock.fadeOut(200);
        setTimeout(function(){
            document.body.removeChild(animationBlock);
        },delay)
    },delay)
}
module.exports = greetAnimation;