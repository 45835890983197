function typing() {
    let typings = document.querySelectorAll('.typing');
typings.forEach(elem=>{
    elem.style.opacity =0;
})
typingAnimation(typings);
} 
function typingAnimation(elements) {
    elements.forEach(element=>{
        let typingObserver = new IntersectionObserver(entries=>{
            entries.forEach(entry=>{

                if(entry.isIntersecting){
                    entry.target.childNodes.forEach(child=>{
                        let text = child.textContent;
                        let index = 0;
                        child.textContent = "";
                        entry.target.style.opacity =1;
                        function type() {
                            let current = text.charAt(index);
                            child.textContent += current;
                            index++;
                            if (index < text.length) {
                            setTimeout(type, 30* Math.random());
                            }
                        }
                
                    type();
                     typingObserver.unobserve(entry.target);
                    })
                }
            })
        },{rootMargin:'-100px'})
        typingObserver.observe(element);
    })
}
module.exports = typing;