// const { entries } = require("core-js/core/array");

function aboutAnimation() {
    let aboutItems = document.querySelectorAll('.about-item');

    // Добавим класс fadePC к элементам
    document.querySelectorAll('.advantages-item, .prices-item, .feedback-item').forEach(item => {
        item.classList.add('fadePC');
    });

    aboutItems.forEach(item => {
        item.classList.add('fadePC');
    });

    fadeInAbout(document.querySelectorAll('.fadePC'));
}

function fadeInAbout(elements) {
    let delay = 250; // Начальная задержка
    const aboutObserver = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                // Применяем задержку для анимации
                setTimeout(() => {
                    entry.target.classList.add('slideRight');
                }, delay);

                // Увеличиваем задержку на 250 мс для следующего элемента

                // Отключаем наблюдение после анимации
                aboutObserver.unobserve(entry.target);
            }
        });
    }, { rootMargin: '-100px' });
    elements.forEach((element, index) => {

        aboutObserver.observe(element);
    });
}

module.exports = aboutAnimation;