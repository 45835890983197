function formAjax() {
    let layout = document.querySelector('.form-layout');
    let popup = document.querySelector('#layout-form');
    let messagePopup = document.querySelector('.fetch-message-item')
    let forms = document.querySelectorAll('form');
    forms.forEach(form=>{
        form.addEventListener('submit', function(e) {
            
            e.preventDefault();
            let nameInput = form.querySelector('input[type="text"]');
            let phoneInput = form.querySelector('input[type="tel"]');
            console.log(nameInput, phoneInput);
            if(nameInput.value.length < nameInput.minLength || phoneInput.value.length < phoneInput.minLength) {
                console.log('bad-value')
                return false
            }

            else {
                showMessagePopup(popup,messagePopup,layout);
            let formData = new FormData(form),
                    doneMessage = 'Контактые данные успешно отправлены.Спасибо.',
                    undoneMessage ='Произошла ошибка на стороне сервера,попробуйте связаться иначе.';
            fetch('/smart.php', {
                method: 'POST',
                body:formData,
            })
            .then(function(data) {
                removeMessagePopup(popup,messagePopup,layout,doneMessage);
                form.querySelectorAll('input').forEach(input=>{
                    input.value="";
                });
            })
            .catch(function(error){
                concole.log(error);
                    removeMessagePopup(popup,messagePopup,layout,undoneMessage);
            });
            }
        })
    })
}
function showMessagePopup(popup,messagePopup,layout) {
    layout.style.display = 'block'
    popup.style.display = 'none';
    messagePopup.style.display ='block';
    document.body.style.overflow = 'hidden';
    messagePopup.querySelector(".fetch-message").innerHTML =`<div class="d-flex justify-content-center">
    <div class="spinner-border" role="status">
      <span class="sr-only"></span>
    </div>
  </div>`

}
function removeMessagePopup(popup,messagePopup,layout,textMessage) {
    messagePopup.querySelector(".fetch-message").innerHTML = '';
    messagePopup.querySelector(".fetch-message").textContent= textMessage;
    setTimeout(function(){
        layout.fadeOut(500);
        setTimeout(function(){
            messagePopup.querySelector(".fetch-message").textContent = "";
            document.body.style.overflow ='visible';
            popup.style.display='block';
        },500)
    },2000);
}
module.exports = formAjax;