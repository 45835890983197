function politics () {
    // try {
    //     let politicsBtn2 = document.querySelector('#politBtn2');
    // let politicsCheck2 = document.querySelector('#politCheck2');
    // let politicsBtn1 = document.querySelector('#politBtn1');
    // let politicsCheck1 = document.querySelector('#politCheck1');
    // console.log(politicsBtn1);
    // politicsCheck1.addEventListener('change', function() {
    //    if(politicsCheck1.checked == true) {
    //     politicsCheck1.value = 'Cогласен';
    //        politicsBtn1.disabled = false;
    //    }
    //    else {
    //        politicsBtn1.disabled = true;
    //        politicsCheck1.value = false;
    //    }
    // })
    // politicsCheck2.addEventListener('change', function() {
    //    if(politicsCheck2.checked == true) {
    //     politicsCheck2.value = 'Cогласен';
    //        politicsBtn2.disabled = false;
    //    }
    //    else {
    //        politicsBtn2.disabled = true;
    //        politicsCheck2.value = false;
    //    }
    // });
    // }
    // catch(e) {
    //     return
    // }
    let politCheckBoxes = document.querySelectorAll('.politCheckbox');
    console.log(politCheckBoxes);
    politCheckBoxes.forEach(item=>{
        item.addEventListener('change',function() {
            console.log('change');
            if(item.checked == true) {
                item.value = 'Согласен';
                item.closest('form').querySelector('button').disabled = false;
            }
            else {
                item.closest('form').querySelector('button').disabled = true;
                item.value = 'Не Согласен';
                }
        })
    })
}
module.exports = politics;