function layoutFunc () {
    let layout = document.querySelector('.form-layout');
    let popup = document.querySelector('#layout-form');
    let politicsBtn1 = document.querySelector('#politBtn1');
    let politicsCheck1 = document.querySelector('#politCheck1');

    let modalBtns = document.querySelectorAll('.modal_btn');
    modalBtns.forEach(btn=>{
        btn.addEventListener('click' , function(e) {
            layout.fadeIn(200);
            document.body.style.overflow = "hidden";
            setTimeout(function(){
                layout.addEventListener('click',deleteLayOut)
            },1000)
            function deleteLayOut(e) {
                if(!popup.contains(e.target)||e.target == document.querySelector('.close')) {
                    layout.fadeOut(500);
                    layout.removeEventListener("click",deleteLayOut);
                    document.body.style.overflow = 'visible';
                    politicsCheck1.checked = false;
                    politicsBtn1.disabled = true;
                }
            }
        });
    })
}
module.exports = layoutFunc;