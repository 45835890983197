function fadeInMobile() {
        const screenShots = document.querySelectorAll('.case');
        const observer = new IntersectionObserver(entries=> {
         entries.forEach(entry => {
           entry.target.classList.toggle("screen_visible", entry.isIntersecting);
         })
       
        },{threshold:0.25,})
        screenShots.forEach(screenShot => {
         observer.observe(screenShot);
        })
        fadeMobile(document.querySelectorAll('.fadeMobile'));
}

   function fadeMobile(elements) {
    let cardObserver = new IntersectionObserver(entries=>{
        entries.forEach(entry=>{

            if(entry.isIntersecting){
                entry.target.classList.add('slideRight');
            }
        })
    },{rootMargin:'-100px'})
        elements.forEach(element=>{
            cardObserver.observe(element);
        })
}
module.exports = fadeInMobile;