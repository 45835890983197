function burger() {
    let hamburger = document.querySelector('#hamburger'),
        mobileNavbar = document.querySelector('.mobile-navbar'),
        overlay = document.querySelector('.overlay');
    hamburger.addEventListener('click',function(){
        hamburger.classList.toggle('hamburger-active');
        overlay.classList.toggle('overlay-active');
        mobileNavbar.classList.toggle('mobile-navbar-active');
        document.body.classList.toggle('body-overlayed');
    })
    mobileNavbar.addEventListener('click', function(e) {
        console.log(e.target);

        if( e.target.matches('a')) {
            mobileNavbar.classList.remove('mobile-navbar-active');
            hamburger.classList.remove('hamburger-active');
            overlay.classList.remove('overlay-active');
            document.body.removeAttribute('class');
        }
        else {
            return
        }
    })
    overlay.addEventListener('click', function(e) {
        mobileNavbar.classList.remove('mobile-navbar-active');
            hamburger.classList.remove('hamburger-active');
            document.body.removeAttribute('class');
            overlay.classList.remove('overlay-active')

    })
}


module.exports = burger;